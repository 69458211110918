@keyframes slideUpFadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.carousel-wrapper {
  position: relative;
  width: 100%;
}

.carousel-div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  height: 85vh;
  max-height: 900px;
  background: #000;
  position: relative;
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 4s ease-in-out;
}

.carousel-img.zoom {
  animation: zoomIn 4s ease forwards;
}

.custom-dot {
  width: 32px !important;
  height: 8px !important;
  border-radius: 10px !important;
  background-color: #fff;
  opacity: 0.6;
  transition: background-color 0.3s ease, width 0.3s ease;
  z-index: 4;
}

.slick-dots li.slick-active .custom-dot {
  background-color: #fff;
  width: 35px !important;
  margin-right: 10px !important;
  opacity: 1;
}

.carousel-item-text {
  position: absolute;
  font-size: 42px;
  letter-spacing: 2%;
  font-weight: 500;
  color: #fafaf899;
  z-index: 5;
  bottom: 50px;
  left: 100px;
  width: 80%;
  text-wrap: wrap;
  opacity: 0;
  text-transform: uppercase;
}

.carousel-item-text span {
  color: #fafaf8;
}

.carousel-item-text.animate {
  animation: slideUpFadeIn 1s ease-in-out;
  opacity: 1;
}

.overlay-carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 42.61%,
    rgba(0, 0, 0, 0.6) 100%
  );
  top: 0;
  z-index: 2;
}

/* vision componets style */
.vision-main-container {
  width: 100%;
  background: radial-gradient(
    88.02% 343.13% at 0% 0%,
    rgba(7, 35, 74, 0.12) 0%,
    rgba(177, 201, 202, 0.096) 30%,
    rgba(191, 169, 215, 0.0956571) 64.5%,
    rgba(143, 96, 132, 0.12) 100%
  );
  margin: 0px auto;
  box-sizing: border-box;
  overflow: hidden;
  padding: 72px 80px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
}

.vision-disc-text {
  font-size: 20px;
  letter-spacing: 2%;
  text-align: center;
  line-height: 30px;
  font-weight: 500;
  color: transparent;
  background: linear-gradient(
      90.23deg,
      rgba(29, 45, 68, 0.8) 24.03%,
      rgba(115, 140, 171, 0.8) 75.25%
    ),
    linear-gradient(
      95.2deg,
      rgba(7, 35, 74, 0.26) -1.5%,
      rgba(143, 96, 132, 0.26) 60.92%
    );
  -webkit-background-clip: text;
  background-clip: text;
  text-wrap: wrap;
  max-width: 780px;
}

.vision-header-text {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 2%;
  color: #07234adb;
}

/* contact home component style  */

.contactSvgMain {
  position: relative;
}

.contact-home-main-div {
  background-color: rgba(250, 250, 248, 0.72);
  max-width: 1064px;
  width: 100%;
  padding: 32px;
  box-shadow: -10px 0px 20px -25px rgba(191, 169, 215, 0.6),
    /* Left-side shadow */ 0px 10px 24px -20px rgba(21, 21, 21, 0.46);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
}

.contactEllipse1 {
  position: absolute;
  background-image: url("/src/assets/images/ContactEllipse1.png");
  background: rgba(191, 169, 215, 0.6);
  height: 300px;
  width: 300px;
  opacity: 0.12;
  box-shadow: 0px 50px 120px 200px rgba(191, 169, 215, 0.6);
  top: 153.34px;
  border-radius: 50%;
}

.contactEllipse2 {
  position: absolute;
  background-image: url("/src/assets/images/ContactEllipse2.png");
  top: 198.99px;
  left: 347.65px;
  height: 243.47px;
  width: 243.37px;
  background: rgba(182, 251, 255, 0.78);
  box-shadow: 0px 0px 70px 50px rgba(182, 251, 255, 0.78);
  border-radius: 50%;
  opacity: 0.12;
}

.contactEllipse3 {
  position: absolute;
  background-image: url("/src/assets/images/ContactEllipse3.png");
  top: 0;
  left: 639.11px;
  border-radius: 50%;
  height: 271.57px;
  width: 271.57px;
  background: rgba(182, 251, 255, 0.66);
  box-shadow: 0px 0px 30px 50px rgba(182, 251, 255, 0.66);
  opacity: 0.12;
  border-radius: 50%;
}

.contactEllipse4 {
  background-image: url("/src/assets/images/ContactEllipse4.png");
  background: rgba(131, 164, 212, 0.6);
  box-shadow: 0px 30px 100px 120px rgba(131, 164, 212, 0.6);
  position: absolute;
  height: 261.03px;
  width: 261.03px;
  top: 153.34px;
  left: 958.67px;
  border-radius: 50%;
  opacity: 0.1;
}

.contact-home-header {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 2%;
  color: #07234adb;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.mv-40 {
  margin: 40px 0px;
}

.home-contact-desc {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #07234a;
  letter-spacing: 2%;
  text-align: center;
  white-space: pre-wrap;
}

.home-contact-btn {
  padding: 12px 20px;
  background-color: #093554;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: all 0.4s;
}

.home-contact-btn:hover {
  transform: translateY(-5px);
}

.home-contact-btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
}

.margin-top-40 {
  margin-top: 40px;
}

/* service card components */

.service-card-div {
  background-color: rgba(250, 250, 248, 0.72);
  min-width: 400px;
  width: 100%;
  padding: 40px 32px;
  border: 0.6px solid #eaeaea;
  box-shadow: 0px 10px 24px -20px #15151575;
  border-radius: 5px;
  transition: all 0.4s;
  position: relative;
  flex: 1;
  z-index: 999;
}

.service-card-div:hover {
  transform: translateY(-10px);
}

.sevice-card-header {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.service-icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.service-card-header-text {
  font-size: 16px;
  color: #15273d;
  font-weight: 500;
  letter-spacing: 2%;
}

.service-card-desc {
  color: #15273d;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

.mt-26 {
  margin-top: 26px;
}

/* about component */
.about-home-div {
  max-width: 1980px;
  width: 100%;
  max-height: 883px;
  position: relative;
}

.about-home-image {
  width: 100%;
  height: auto;
  min-height: 350px;
  max-height: 883px;
  object-fit: cover;
  object-position: center center;
}

.overlay-about-home {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4536) 60.5%,
    rgba(0, 0, 0, 0.72) 100%
  );
  top: 0;
}

.about-home-info-div {
  position: absolute;
  top: 42px;
  left: 80px;
  max-width: 521px;
}

.about-home-header {
  font-size: 32px;
  font-weight: 500;
  color: #fafaf8;
  line-height: 39px;
  text-transform: uppercase;
}

.about-home-header-desc {
  font-size: 16px;
  font-weight: 400;
  color: #fafaf8;
  line-height: 24px;
  opacity: 0.8;
  margin: 24px 0px;
  max-height: 220px;
  overflow: hidden;
}

.about-know-more-btn {
  padding: 12px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(7, 35, 74, 0.12), rgba(7, 35, 74, 0.12));
  transition: all 0.3s;
}

.about-know-more-btn:hover {
  transform: translateY(-5px);
}

.about-know-more-text {
  font-size: 16px;
  font-weight: 500;
  color: #093554;
}

/* hero component */
.hero-div {
  min-height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 388px;
  flex: 1;
}

.hero-header-text {
  font-size: 32px;
  font-weight: 500;
  color: #07234adb;
  line-height: 41px;
  letter-spacing: 2%;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.hero-desc {
  font-size: 18px;
  font-weight: 300;
  color: #1d2d44;
  line-height: 27px;
  max-height: 400px;
  overflow: hidden;
}

.hero-desc-modal {
  font-size: 18px;
  font-weight: 300;
  color: #1d2d44;
  line-height: 27px;
}

.about-modal-first-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.about-info-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.about-data-modal {
  width: 800px !important;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0px 15px 26px -22px rgba(21, 21, 21, 0.58);
  padding: 20px 18px;
  border-radius: 14px;
}

@media screen and (max-width: 1024px) {
  .contactEllipse1,
  .contactEllipse2,
  .contactEllipse3,
  .contactEllipse4 {
    display: none;
  }

  .hero-div {
    min-width: 100%;
    min-height: auto;
  }

  .hero-header-text {
    text-align: center;
  }

  .service-card-div {
    min-width: 360px;
  }
}

@media screen and (max-width: 768px) {
  .carousel-item-text {
    font-size: 20px;
    line-height: 26px;
    left: 20px;
    bottom: 80px;
  }

  .about-home-header {
    font-size: 16px;
    line-height: 20px;
  }

  .about-home-header-desc {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 15px;
    margin-top: 0px;
    max-height: 200px;
  }

  .about-home-info-div {
    left: 0;
    top: 0;
    padding: 20px;
    max-width: 360px;
  }

  .about-know-more-btn,
  .home-contact-btn {
    padding: 12px 16px;
  }

  .about-know-more-text,
  .home-contact-btn-text {
    font-size: 12px;
  }

  .hero-header-text {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .hero-desc,
  .hero-desc-modal {
    font-size: 12px;
    line-height: 18px;
  }

  .service-card-div {
    justify-content: center;
    align-items: center;
    padding: 14px 10px;
  }

  .sevice-card-header {
    justify-content: center;
  }

  .service-card-header-text {
    font-size: 14px;
    line-height: 18px;
  }

  .mt-26 {
    margin-top: 13px;
  }

  .vision-main-container {
    padding: 26px 20px;
    gap: 14px;
  }

  .vision-header-text {
    font-size: 16px;
  }

  .vision-disc-text {
    font-size: 12px;
    line-height: 18px;
  }

  .contact-home-header {
    font-size: 18px;
  }

  .mv-40 {
    margin: 14px 0px;
  }

  .home-contact-desc {
    font-size: 12px;
    line-height: 18px;
  }

  .contact-home-main-div {
    padding: 20px;
  }

  .margin-top-40 {
    margin-top: 4px;
  }
}

@media screen and (max-width: 380px) {
  .home-contact-btn {
    padding: 10px;
  }
}
