.about-section-1 {
  max-width: 1440px;
  width: 100%;
  margin: 0px auto;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mv-24 {
  margin: 24px 0px;
}

.about-text {
  font-size: 42px;
  color: #07234adb;
  font-weight: 500;
  letter-spacing: 2%;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0px;
}

.about-info-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}

.about-info-text-1 {
  text-align: center;
  font-size: 20px;
  color: #1d2d44;
  font-weight: 500;
}

.about-info-text-2 {
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  color: #1d2d44;
  font-weight: 300;
}

.about-section-2 {
  position: relative;
  max-width: 1980px;
  width: 100%;
  min-height: 280px;
  height: auto;
  margin: 0px auto;
}

.overlay-about-1 {
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 42.61%,
    rgba(0, 0, 0, 0.6) 100%
  );

  width: 100%;
  height: 100%;
  top: 0;
}

.about-image-1 {
  width: 100%;
  min-height: 280px;
  max-height: 607px;
  height: auto;
  object-fit: cover;
  object-position: center center;
}

.about-section-3 {
  width: 100%;
  max-width: 1440px;
  padding: 60px 160px;
  margin: 0px auto;
  font-size: 18px;
  color: #1d2d44;
  font-weight: 300;
  line-height: 27px;
  text-align: center;
}

.about-section-3::first-letter {
  font-size: 28px;
}

.about-section-4 {
  width: 100%;
  background: linear-gradient(
    360deg,
    rgba(177, 201, 202, 0) 55.62%,
    rgba(177, 201, 202, 0.12) 100%
  );
}

.inner-div-section-4 {
  max-width: 1440px;
  width: 100%;
  padding: 80px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
}

.about-section-4-header {
  font-size: 32px;
  font-weight: 500;
  color: #07234adb;
  letter-spacing: 2%;
  margin-bottom: 0;
}

.about-section-4-desc {
  font-size: 18px;
  line-height: 27px;
  color: #1d2d44;
  font-weight: 300;
  text-align: center;
}

.overlay-about-2 {
  position: absolute;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 21.11%,
    rgba(0, 0, 0, 0.63) 61.4%,
    #000000 100%
  );

  width: 100%;
  height: 100%;
  top: 0;
}

.about-5-shadow {
  box-shadow: 10px 0px 36px 0px #15151540;
}

.about-5-header-text {
  font-size: 28px;
  font-weight: 500;
  color: #f9f9f8;
}

.about-5-desc-text {
  font-size: 16px;
  color: #f9f9f8;
  font-weight: 300;
  line-height: 24px;
}

.about-5-div {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  top: 60px;
  left: 80px;
  max-width: 521px;
}

.about-section-6 {
  max-width: 1440px;
  width: 100%;
  padding: 100px 80px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-section-6-header {
  font-size: 32px;
  color: #07234adb;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2%;
  margin-bottom: 0px;
  text-align: center;
}

.about-section-6-desc {
  font-size: 18px;
  font-weight: 300;
  color: #1d2d44;
  line-height: 27px;
  margin-top: 24px;
}

.skill-list-div {
  width: 246px;
  padding: 12px;
  height: 78px;
  background-color: #fafaf885;
  box-shadow: 0px 10px 24px -20px #15151575;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #1d2d44;
  font-weight: 500;
  text-align: center;
}

.skills-div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 80px;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .about-5-div {
    top: 0;
    left: 0;
    padding: 40px;
  }

  .inner-div-section-4 {
    padding: 60px 40px;
  }

  .about-section-3 {
    padding: 60px 40px;
  }

  .about-section-1 {
    padding: 60px 40px;
  }

  .about-section-6 {
    padding: 60px 40px;
  }
}

@media screen and (max-width: 768px) {
  .about-text {
    font-size: 20px;
    line-height: 26px;
  }
  .about-section-1 {
    padding: 40px 20px;
  }

  .mv-24 {
    margin: 12px 0px;
  }

  .about-info-text-1 {
    font-size: 12px;
    line-height: 18px;
  }

  .about-info-text-2 {
    font-size: 10px;
    line-height: 15px;
  }

  .about-section-3 {
    padding: 32px 20px;
    font-size: 12px;
    line-height: 18px;
  }

  .about-section-3::first-letter {
    font-size: 14px;
  }

  .inner-div-section-4 {
    padding: 40px 20px;
  }

  .about-section-4-header {
    font-size: 16px;
  }

  .about-section-4-desc {
    font-size: 12px;
    line-height: 16px;
  }

  .about-5-div {
    top: 0;
    left: 0;
    padding: 20px;
  }

  .about-5-header-text {
    font-size: 14px;
    line-height: 16px;
  }

  .about-5-desc-text {
    font-size: 12px;
    line-height: 18px;
  }

  .about-section-6 {
    padding: 32px 20px;
  }

  .about-section-6-header {
    font-size: 16px;
    line-height: 20px;
  }

  .about-section-6-desc {
    margin-top: 12px;
    font-size: 12px;
    line-height: 18px;
  }

  .skills-div {
    margin-top: 16px;
  }

  .skill-list-div {
    padding: 6px;
    font-size: 10px;
    height: 50px;
    width: 100px;
  }
}
