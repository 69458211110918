.footer-main-div {
  width: 100%;
  margin: 0px auto;
  background: linear-gradient(180deg,
      rgba(115, 140, 171, 0) 0%,
      rgba(115, 140, 171, 0.2) 100%);
  display: flex;
  justify-content: center;
}

.footer-main {

  max-width: 1440px;
  width: 100%;
  padding: 20px 80px;
}

.logo-footer-text {
  font-size: 24px;
  color: #172940;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.logo-footer-tagline-text {
  font-size: 8px;
  line-height: 10px;
  color: #172940;
  font-weight: 400;
  letter-spacing: 1.2px;
  transition: all 0.5s ease;
}

.footer-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 10px;
}

.nav-link-footer {
  padding: 8px 10px;
  color: #15273d;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.5s ease;
}

.footer-nav-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  transition: all 0.5s ease;
}

.linkdin-icon {
  width: 26px;
  height: 26px;
  object-fit: contain;
  border-radius: 4px;
}

.footer-social-btn {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.footer-country-name {
  font-size: 14px;
  color: #172940;
  font-weight: 400;
  margin-bottom: 10px;
}

.footer-address-text {
  font-size: 12px;
  color: #172940;
  line-height: 16px;
  font-weight: 300px;
  text-transform: capitalize;
  margin: 0px;
}

.sms-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.footer-email-text {
  font-size: 12px;
  color: #172940;
  font-weight: 300;
}

.footer-email-div,
.footer-phone-div {
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin: 5px 0px;
  text-decoration: none;
  cursor: pointer;
}

.footer-address-div {
  max-width: 333px;
  width: 100%;
  transition: all 0.5s ease;
}

.footer-main-address {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 20px;
}

.copy-right-text {
  font-size: 12px;
  color: #15273d;
  font-weight: 500;
  transition: all 0.5s ease;
}

.footer-link {
  font-size: 12px;
  font-weight: 500;
  color: #15273d;
  transition: all 0.5s ease;
}

.footer-link-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  row-gap: 10px;
  flex-wrap: wrap;
  transition: all 0.4s ease;
}

.footer-rights-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  transition: all 0.5s ease;
}

.logoFooter {
  background-image: url("/src/assets/icons/LogoFooter.svg");
  width: 38.29px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.noLogoDiv {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  transition: all 0.5s ease;
}

.logoDiv {
  display: flex;
  flex: 0.5;
}

.contMailDiv {
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease;
}

@media screen and (max-width:1266px) {
  .footer-address-div {
    max-width: 158px;
  }
}

@media screen and (max-width: 1084px) {

  .footer-main {
    padding: 22px 40px;
  }

  .footer-nav-div {
    flex-direction: column;
  }

  .noLogoDiv {
    margin-top: 32px;
    width: 100%;
  }

}

@media screen and (max-width:768px) {
  .noLogoDiv {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .footer-main-address {
    flex-direction: column;
    max-width: 350px;
  }

  .footer-address-div {
    max-width: fit-content;
  }

  .contMailDiv {
    flex-direction: row;
    column-gap: 7.81px;
  }

  .footer-main {
    padding: 22px 20px
  }

  .footer-email-div {
    width: 202px;
  }

}


@media screen and (max-width: 598px) {
  .nav-link-footer {
    font-size: 12px;
  }

  .footer-rights-div {
    margin-top: 14px;
    flex-direction: column;
    row-gap: 10px;
  }
}

@media screen and (max-width:468px) {
  .logoFooter {
    width: 22.97px;
    height: 24px;
  }

  .logo-footer-text {
    font-size: 14.61px;
    line-height: 18.99px;
  }

  .logo-footer-tagline-text {
    font-size: 4.8px;
    line-height: 6.24px;
  }

  .footer-address-text {
    font-size: 10px;
    line-height: 15px;

  }

  .footer-country-name {
    font-size: 10px;
    line-height: 12.19px
  }

  .footer-email-text {
    font-size: 10px;
    line-height: 12.19px;
  }

  .copy-right-text {
    font-size: 10px;
    line-height: 8.42px;
  }

  .footer-link {
    font-size: 10px;
    line-height: 8.42px;
  }

  .nav-link-footer {
    font-size: 10px;
  }

  .footer-phone-div {}
}


@media screen and (max-width: 408px) {
  .footer-nav {
    column-gap: 0px;
  }

}

@media screen and (max-width:395px) {
  .contMailDiv {
    flex-direction: column;
    row-gap: 7px;
  }
  
  .footer-email-div,.footer-phone-div {
    margin: 0px;
  }

  .footer-address-text {
    margin-bottom: 6px;
  }
}