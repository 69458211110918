@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

.main-container {
  width: 100%;
  background-color: #fafaf8;
  min-height: 100vh;
  margin: 0px auto;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.main-home-section {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
}

.main-section {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  flex: 1;
}

.custom-toast {
  width: 400px !important;
  background-color: #093554 !important;
  color: #fff !important;
}

.custom-toast .Toastify__close-button {
  color: #fffe !important;
  opacity: 0.8 !important;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td,
.cancelBtn,
.applyBtn,
.drp-selected {
  font-size: 13px;
  font-family: "Montserrat";
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #24325f !important;
}

.daterangepicker td.in-range {
  background-color: rgba(154, 192, 252, 0.6);
}

.location-input {
  display: flex;
  align-items: center;
  background-color: #f8f6f6;
  padding: 10px 12px;
  gap: 8px;
  border-radius: 12px;
}

.location-input-field {
  font-size: 14px;
  font-weight: 500;
  color: rgba(9, 53, 84, 1);
  padding: 0px;
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
}

.location-input-field::placeholder {
  color: #093554ad;
  font-weight: 300;
}

.candidate-desc {
  color: #093554;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 20px 0px;
}

.candidate-skills-text {
  font-size: 14px;
  line-height: 20px;
  color: #093554b2;
  font-weight: 400;
}

.resume-btn {
  text-decoration: none;
  padding: 14px 20px;
  border-radius: 12px;
  min-height: 45px;
  background-color: #015c93;
  color: #fafaf8;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat";
  border: none;
  text-transform: capitalize;
  outline: none;
}

.resume-btn:hover {
  text-decoration: none;
}

.candidate-name {
  font-size: 24px;
  color: #093554;
  font-weight: 600;
  max-width: 60%;
}

.skills-label {
  padding: 10px 12px;
  height: 35px;
  background-color: #53a6d847;
  border-radius: 8px;
  color: #093554cc;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.language-label {
  padding: 10px 12px;
  height: 35px;
  background-color: #53a6d847;
  border-radius: 8px;
  color: #093554cc;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.publication-label {
  color: #093554cc;
  font-size: 12px;
  font-weight: 500;
}

.location-box {
  background-color: #f8f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 12px;
}

.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.custom-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1.5px solid rgba(9, 53, 84, 0.6);
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.custom-checkbox input:checked ~ .checkmark {
  border-color: rgba(9, 53, 84, 1);
}

.custom-checkbox input:disabled ~ .checkmark {
  border-color: rgba(9, 53, 84, 0.6);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid rgba(9, 53, 84, 1);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.location-label-text {
  font-size: 12px;
  color: rgba(9, 53, 84, 1);
  font-weight: 400;
}

/* coockies styles */

.popup-container {
  height: 100vh;
  position: fixed;
  /* top: 0; */
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 2222;
}

.cookies-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accept-cookie-btn {
  padding: 12px 16px;
  background-color: #fff;
  border-radius: 12px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  color: #24325f;
}

.cookies-first-div {
  width: 80%;
}

.cookies-privacy-text {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.cookies-desc-text {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.padding-20 {
  padding: 20px;
}

.loadermaindivModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Light semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Ensure it covers everything */
  pointer-events: none; /* Allows interaction with underlying content */
}

.loaderModal {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #093554;
}
.loaderModal:before,
.loaderModal:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loaderModal:after {
  color: #ff3d00;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@media (max-width: 1024px) {
  .cookies-div {
    flex-direction: column;
  }

  .cookies-desc-text {
    text-align: center;
  }

  .cookies-privacy-text {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .custom-toast {
    width: auto !important;
  }

  .cookies-privacy-text {
    font-size: 18px;
  }

  .cookies-desc-text {
    font-size: 12px;
  }

  .accept-cookie-btn {
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 6px;
  }

  .cookies-first-div {
    width: 90%;
  }

  .candidate-name {
    font-size: 18px;
  }

  .card {
    height: auto !important;
  }
}
