.blogs-main-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 76px 120px;
  column-gap: 120px;
  row-gap: 100px;
  transition: all 0.5s ease;
}

.blogMainDiv {
  min-width: 540px;
  width: 100%;
  flex: 1;
  transition: all 0.5s ease;
}

.viewmoreMain {
  width: 100%;
  max-width: 1400px;
  background-color: #fafaf8;
  justify-content: space-between;
  display: flex;
  padding-inline: 40px;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px auto 20px;
  box-sizing: border-box;
  transition: all 0.5s ease;
  flex-direction: column;
}

.blogDate {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #093554;
}

.blogTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  color: #093554;
  transition: all 0.5s ease;
}

.blogDesc {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(9, 53, 84, 0.7);
  margin: 0px;
}

.continueReadingTxt {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #015c93;
  cursor: pointer;
  margin: 20px 0px 0px;
}

.backBtnDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 104px;
  column-gap: 15px;
  border-radius: 8px;
  padding: 12px 14px;
  background-color: #ececec;
  /* background-color: red; */
  cursor: pointer;
}

.backTxt {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: rgba(9, 53, 84, 0.8);
  margin-bottom: 0px;
}

.backArrowIcon {
  width: 26px;
  height: 26px;
}

.blogMoreDetails {
  padding: 30px 0 30px 0;
}

.datePostedbyDiv {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
}

.postedName {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #093554;
}

.likeImg {
  height: 20px;
  width: 20px;
}

.likeTxt {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #093554;
  margin-bottom: 0px;
}

.likeDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
  padding: 10px 16px;
  border: 1px #093554 solid;
  border-radius: 12px;
  cursor: pointer;
}

.nonSocialMediaDiv,
.socialMediaDiv {
  display: flex;
  flex-direction: row;
  column-gap: 14px;
  transition: all 0.5s ease;
}

.interactBtnsMainDiv {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.5s ease;
}

.socialImg {
  height: 24px;
  width: 24px;
}

.shareOnTxt {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #fafaf8;
  margin-bottom: 0px;
}

.linkedinDiv,
.twitterDiv {
  background-color: #015c93;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  border-radius: 12px;
  cursor: pointer;
}

.twitterDiv {
  background-color: #093554;
}

.blogPageTitle {
  font-size: 42px;
  font-weight: 500;
  line-height: 54.6px;
  color: rgba(7, 35, 74, 0.86);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0px;
  transition: all 0.5s ease;
}

.blogPageTitleDiv {
  margin-top: 76px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: center;
  align-items: center;
}

.blogPageDesc {
  font-size: 18px;
  line-height: 27px;
  color: #1d2d44;
  font-weight: 300;
  text-align: center;
  text-wrap: wrap;
  padding-inline: 120px;
  margin-bottom: 0px;
  transition: all 0.5s ease;
}

.blogBorder {
  border: 1px #818dbd solid;
  width: 100%;
  max-width: 158px;
}

.blog-home-div {
  background: linear-gradient(
    360deg,
    rgba(7, 35, 74, 0) 24.44%,
    rgba(7, 35, 74, 0.048) 100%
  );
  border-radius: 5px;
  min-width: 400px;
  padding: 36px;
  box-shadow: 0px 12px 26px -20px #15151575;
  transition: all 0.4s;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-home-div:hover {
  transform: translateY(-10px);
}

.blog-home-title {
  font-size: 20px;
  line-height: 24px;
  color: #15273d;
  font-weight: 500;
  text-transform: capitalize;
}

.blog-home-desc {
  color: #15273d;
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
  margin-bottom: 0;
}

.blog-home-learn {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  outline: none;
  padding: 0;
  background: linear-gradient(0deg, #07234a, #07234a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 1px solid #07234a;
  opacity: 1;
  transition: opacity 450ms ease;
}

.blog-home-learn:hover {
  opacity: 0.8;
}

.blog-home-learn-div {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* margin: 24px 0px; */
}

.blog-home-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.blog-auther {
  font-size: 14px;
  font-weight: 400;
  color: #55616f;
}

.mt60 {
  margin-top: 60px;
}

.descLearnDiv {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  flex: 1;
  justify-content: space-between;
  padding: 0 0 12px 0;
  border-bottom-color: #818dbd1f;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.blog_desc-data h1 {
  font-size: 20px;
  color: rgba(9, 53, 84, 0.7);
}

.blog_desc-data p {
  font-size: 14px;
  color: rgba(9, 53, 84, 0.7);
}

.blog_desc-data ul {
  font-size: 14px;
  color: rgba(9, 53, 84, 0.7);
}

@media screen and (max-width: 1154px) {
  .blogMain {
    justify-content: center;
    row-gap: 100px;
  }

  .blogPageDesc {
    padding-inline: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .blogs-main-div {
    margin: 60px 120px;
    column-gap: 0px;
    row-gap: 100px;
  }
}

@media screen and (max-width: 768px) {
  .blogs-main-div {
    margin: 40px;
    column-gap: 0px;
    row-gap: 24px;
  }

  .blogMainDiv {
    min-width: 400px;
  }

  .blogPageTitle {
    font-size: 25px;
    line-height: 26px;
  }

  .blogPageDesc {
    font-size: 16px;
  }

  .blogMain {
    row-gap: 20px;
  }

  .continueReadingTxt {
    margin: 8px 0 0;
  }

  .blogTitle {
    margin-bottom: 8px;
  }

  .blogDate {
    margin-bottom: 8px;
  }

  .blog-home-title {
    font-size: 14px;
    line-height: 18px;
  }

  .blog-home-div {
    padding: 16px;
    width: 100%;
    min-width: 300px;
  }

  .blog-home-desc {
    flex: 1;
    font-size: 12px;
    line-height: 18px;
    /* margin: 12px 0px; */
  }

  .blog-home-learn-div {
    margin-top: 10px;
  }

  .blog-home-learn {
    font-size: 12px;
  }

  .blog-auther {
    font-size: 12px;
  }

  .blogBorder {
    max-width: 82px;
  }

  .blogPageTitle {
    font-size: 20px;
  }

  .blogPageDesc {
    font-size: 12px;
  }

  .postedName {
    align-self: flex-end;
    font-size: 12px;
  }

  .blogDate {
    font-size: 10px;
    align-self: flex-start;
  }

  .blogBorder {
    max-width: 73.88px;
  }

  .blogTitle {
    font-size: 12px;
  }

  .blogDesc {
    font-size: 12px;
  }

  .continueReadingTxt {
    font-size: 10px;
  }

  .backTxt {
    font-size: 12px;
  }

  .backArrowIcon {
    height: 16px;
    width: 16px;
  }

  .likeTxt {
    font-size: 12px;
  }

  .shareOnTxt {
    font-size: 12px;
  }

  .socialImg {
    height: 20px;
    width: 20px;
  }

  .interactBtnsMainDiv {
    flex-direction: column;
    row-gap: 20px;
  }
}

@media screen and (max-width: 480px) {
  .blogMainDiv {
    min-width: 200px;
  }
}

@media screen and (max-width: 360px) {
  .linkedinDiv,
  .twitterDiv {
    justify-content: center;
  }

  .likeDiv {
    justify-content: center;
  }
}
