.main-carousel-section {
  width: 100%;
  max-width: 1980px;
  margin: 0px auto;
  box-sizing: border-box;
}

.contact-home-section {
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.mv-100 {
  margin: 100px 0px;
}

/* blog section */

.blog-home-header-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.blog-home-header {
  font-size: 32px;
  color: #07234adb;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.home-blog-list-div {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.blog-home-section {
  max-width: 1440px;
  width: 100%;
  padding: 100px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.blog-home-main-container {
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.blog-home-main-container1 {
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.mt-40 {
  margin-top: 40px;
}

.mb-100 {
  margin-bottom: 100px;
}

/* services section */
.sevice-header-text {
  font-size: 32px;
  color: #15273d;
  font-weight: 500;
}

.sevices-tag-line {
  color: #607d93;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin-top: 20px;
}

.services-card-div {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 40px;
  justify-content: center;
}

.ellipse26 {
  position: absolute;
  background-image: url("/src/assets/images/Ellipse26.png");
  box-shadow: 0px 0px 100px 50px rgba(182, 251, 255, 0.66);
  background: rgba(182, 251, 255, 0.66);
  opacity: 0.22;
  border-radius: 50%;
  background-position: center;
  height: 232px;
  width: 232px;
  top: 147px;
  left: 511px;
}

.ellipse27 {
  position: absolute;
  background-image: url("/src/assets/images/Ellipse27.png");
  border-radius: 50%;
  background-position: center;
  background: rgba(182, 251, 255, 0.78);
  opacity: 0.2;
  box-shadow: 0px 0px 100px 50px rgba(182, 251, 255, 0.78);
  height: 208px;
  width: 208px;
  top: 317px;
  left: 1016px;
}

.ellipse25 {
  position: absolute;
  background-image: url("/src/assets/images/Ellipse25.png");
  border-radius: 50%;
  background-position: center;
  box-shadow: 0px 0px 100px 80px rgba(131, 164, 212, 0.82);
  height: 223px;
  background: rgba(131, 164, 212, 0.82);
  opacity: 0.15;
  width: 223px;
  top: 278px;
  left: 727px;
}

.ellipse28 {
  position: absolute;
  background-image: url("/src/assets/images/Ellipse28.png");
  background: rgba(191, 169, 215, 0.6);
  opacity: 0.2;
  box-shadow: 0px 0px 100px 180px rgba(191, 169, 215, 0.6);
  border-radius: 50%;
  background-position: center;
  height: 256px;
  width: 256px;
  top: 278px;
  left: 209px;
}

.hero-section-div {
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 58px;
  padding: 100px 80px;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 1224px) {
  .hero-section-div {
    gap: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .hero-section-div {
    flex-direction: column;
    gap: 58px;
    padding: 40px;
  }

  .hero-know-more-btn {
    display: flex;
    justify-content: center;
  }

  .services-card-div {
    gap: 34px;
  }

  .blog-home-section {
    padding: 40px;
  }
}

@media screen and (max-width: 768px) {
  .hero-section-div {
    padding: 40px 20px;
    gap: 18px;
  }

  .sevice-header-text {
    font-size: 16px;
    line-height: 20px;
  }

  .sevices-tag-line {
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
  }

  .blog-home-section {
    padding: 40px 20px;
  }

  .services-card-div {
    margin-top: 20px;
    gap: 10px;
  }

  .blog-home-header {
    font-size: 18px;
  }

  .mt-40 {
    margin-top: 20px;
  }

  .mb-100 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 480px) {
  .home-blog-list-div {
    gap: 12px;
  }
}

@media screen and (max-width: 380px) {
  .blog-home-header {
    font-size: 16px;
  }
}
