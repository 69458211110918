.gdprMain {
  max-width: 1440px;
  width: 100%;
  padding: 100px 120px;
  margin: 0px auto;
  transition: all 0.5s ease;
}

.gdprPolicyHeader {
  font-size: 34px;
  color: #07234adb;
  line-height: 44.2px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.5s ease;
  margin-bottom: 0px;
}

.gdprPolicyDesc {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #1d2d44;
}

.gdprPolicyContentTitle {
  /* font-size: 20px;
  line-height: 24.38px;
  font-weight: 600; */
  color: #093554;
  font-family: "Montserrat";
  margin-top: 30px;
}

.gdprPolicySubTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #093554;
}

.gdprPolicySubDesc {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #093554;
}

@media screen and (max-width: 1024px) {
  .gdprMain {
    padding: 40px;
  }
}

@media screen and (max-width: 768px) {
  .gdprPolicyDesc {
    font-size: 12px;
    line-height: 16px;
  }

  .gdprPolicyContentTitle {
    font-size: 12px;
    line-height: 14.63px;
  }

  .gdprPolicySubTitle {
    font-size: 12px;
    line-height: 16px;
  }

  .gdprPolicySubDesc {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width: 480px) {
  .gdprPolicyHeader {
    font-size: 16px;
    line-height: 20.8px;
    color: #07234adb;
  }

  .gdprMain {
    padding: 20px;
  }
}
