.team-tagline-text {
  font-size: 18px;
  color: #1d2d44;
  font-weight: 300;
  text-align: center;
}

.team-section-1 {
  width: 100%;
  max-width: 1440px;
  padding: 100px 80px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 65px;
}

.experince-team-div {
  padding: 5px 14px;
  border-left: 2px solid #015c93;
}

.experience-label {
  font-size: 20px;
  letter-spacing: 2%;
  color: #1d2d44;
  line-height: 26px;
  font-weight: 500;
  text-transform: uppercase;
}

.experience-desc {
  font-size: 16px;
  line-height: 22px;
  color: #1d2d44;
  font-weight: 300;
}

.team-section-2 {
  width: 100%;
  max-width: 1440px;
  padding: 40px 80px 100px 80px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-meet-text {
  text-align: center;
  font-size: 32px;
  color: #15273d;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.team-meet-text-tagline {
  text-align: center;
  font-size: 18px;
  color: #607d93;
  font-weight: 400;
}

.leadership-text {
  font-size: 26px;
  text-align: center;
  color: #15273dcc;
  font-weight: 500;
  margin-bottom: 32px;
  margin-top: 60px;
}

.leader-card-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  flex-wrap: wrap;
}

.partner-card-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 100px;
  margin-top: 100px;
}

.modal-leader-img-div {
  width: 100px;
  height: 100px;
  border-radius: 16px;
}

.modal-leader-img {
  width: 100px;
  height: 100px;
  border-radius: 16px;
  object-fit: fill;
}

.card-data-modal {
  width: 600px !important;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 15px 26px -22px rgba(21, 21, 21, 0.58);
}

.leader-Modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.leader-name {
  color: #093554 !important;
  font-weight: 600 !important;
  font-size: 24px;
}

.modal-leader-name {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.leader-bio {
  color: #093554 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  text-align: center;
}

.leader-info-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  width: 600px;
  padding: 32px;
}

.leader-info-first-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.modal-leader-status {
  font-size: 18px;
  font-weight: 400;
  color: #093554;
}

.leader-modal-wrap {
  display: flex;
  gap: 32px;
}

.url-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .team-tagline-text {
    font-size: 12px;
    line-height: 18px;
  }

  .team-section-1 {
    padding: 40px 20px;
    gap: 20px;
  }

  .experince-team-div {
    padding: 4px 8px;
  }

  .experience-label,
  .experience-desc {
    font-size: 12px;
    line-height: 16px;
  }

  .team-section-2 {
    padding: 40px 20px;
  }

  .team-meet-text {
    font-size: 16px;
  }

  .team-meet-text-tagline {
    font-size: 12px;
    line-height: 16px;
  }
  .leadership-text {
    margin-top: 20px;
    font-size: 20px;
    margin-bottom: 12px;
  }

  .leader-name {
    font-size: 18px !important;
  }
  .leader-bio {
    font-size: 14px !important;
    line-height: 17px !important;
  }

  .partner-card-div {
    row-gap: 60px;
    margin-top: 60px;
  }

  .leader-info-modal {
    width: 100%;
    padding: 20px 16px;
  }

  .leader-modal-wrap {
    flex-direction: column;
    gap: 8px;
  }

  .modal-leader-status {
    font-size: 12px;
  }
}
