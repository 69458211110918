.continerWrapper {
  padding: 10px 1em;

  .containerItems {
    margin-top: 3em;
  }

  .containerItemsSecond {
    margin-top: 0.5em;

    .candidatecardListWrap {
      height: calc(100vh - 2.875rem);
      overflow-y: auto;
      padding: 0 0.2rem 0 0.4rem;
      &::-webkit-scrollbar {
        width: 0.2em;
        border-radius: 2.5px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(83, 166, 216, 0.5);
        border-radius: 10px;
      }
    }

    .count {
      margin-bottom: 1.2em;
      text-align: right;

      span {
        font-size: 12px;
        background-color: #ececec;
        color: #093554cc;
        font-family: "Montserrat";
        padding: 10px 12px;
        border-radius: 8px;
        width: fit-content;
      }
    }
  }
}

.card {
  background-color: #ffffffcc;
  border-radius: 14px;
  padding: 20px;
  border: 1px solid #e9e9e9;
  overflow: auto;
  height: calc(100vh - 2.875rem);
  @media (max-width: 768px) {
    padding: 12px;
    height: auto;
  }

  &.candidateDetailFromListWrap {
    height: calc(100vh - 2.875rem);
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    width: 0.2em;
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(83, 166, 216, 0.5);
    border-radius: 10px;
  }

  .detailWrap {
    padding: 0 1em 1em;
  }

  .detailTitle {
    font-size: 16px;
    font-family: "Montserrat";
    color: #093554;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .primarytitle {
    font-size: 0.9em;
    font-family: "Montserrat";
    color: #283662;
  }

  .detailValue {
    color: #093554b2;
    font-size: 16px;
    font-weight: 400;
    font-family: "Montserrat";
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .actionButton {
    background-color: #e7e7ee;
    color: #6f6f7a;
    margin-right: 1em;
    font-family: "Montserrat";
    width: 11em;
  }

  .darkBlue {
    color: #015c93;
    font-size: 18px;
  }

  .tabWrap {
    border-top: 1px solid #e9e9e9;
    padding: 0 1em;
  }

  .tabDetailTitle {
    color: #09355499;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 400;
  }

  .tabDetailSubTitle {
    color: #093554;
    font-size: 14px;
    font-weight: 500;
    font-family: "Montserrat";
  }

  .tabDetailSubValue {
    font-size: 14px;
    line-height: 20px;
    color: #093554b2;
    font-weight: 400;
    font-family: "Montserrat";
  }

  .skillChips {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0px;
    gap: 12px;

    span {
      background-color: #e7e7ee;
      color: #6f6f7a;
      margin-right: 1em;
      font-family: "Montserrat";
      padding: 2px 8px;
      font-size: 0.7rem;
      border-radius: 10px;
      margin-bottom: 1em;
    }
  }

  .tabs {
    button {
      min-width: 5em;
      padding: 0.8em 1em 0.8em 0;

      span {
        align-items: start;
        color: #6a6a75;
        font-family: "Montserrat";
      }

      &.tab {
        span {
          color: #263460;
          font-family: "Montserrat";
        }
      }
    }

    & > div > span {
      background-color: #263460;
      width: 2.3em !important;
      border-radius: 1em;
      height: 0.16em;
    }
  }

  .darkButton {
    padding: 14px 20px;
    border-radius: 12px;
    height: 45px;
    background-color: #015c93;
    color: #fafaf8;
    font-size: 14px;
    font-weight: 500;
    font-family: "Montserrat";
    border: none;
    text-transform: capitalize;
    outline: none;
  }

  .primaryBtn {
    background-color: #283662;
    color: #ffffff;
  }

  .marginRight {
    margin-right: 1em;
    width: 8em;
  }

  .detailAction {
    color: #bdc0cf;
    margin-left: 6em;
  }

  .skillMore {
    color: #015c93;
    font-size: 16px;
    cursor: pointer;
    font-family: "Montserrat";
  }
}

.pagination {
  margin-bottom: 1.2em;
  display: flex;
  justify-content: center;
  background-color: transparent;

  .listItem {
    font-size: 1rem !important;
    text-decoration: none;
    list-style: none;

    button {
      border: none;
      font-family: "Montserrat";
      padding: 0 0.6em;
      cursor: pointer;
    }

    button:disabled {
      cursor: not-allowed;
    }
  }
}

.filterWrap {
  padding: 20px;
  // max-width: 380px;
  background-color: #ffffffcc;
  border: 1px solid #e9e9e9;
  border-radius: 14px;

  .filterCard {
    font-size: 0.8em;
    font-family: "Montserrat";

    .filterCardBox {
      margin-bottom: 32px;
    }

    .formControl {
      border: none;
      background: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      font-size: 13px;
      font-family: "Montserrat";
      width: 200px;
      text-align: center;
    }

    .filterTitle {
      font-size: 14px;
      color: #093554;
      font-weight: 500;
      font-family: "Montserrat";
    }

    .filterIcon {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .searchBiBox {
      padding-left: 0.3rem;
      position: relative;
      width: 100%;
      // max-height: 4rem;
      overflow: hidden auto;
    }

    .showSeachText {
      word-break: break-word;
      width: 100%;
      font-family: "Montserrat";
      font-weight: 400;
      text-transform: none;
      font-size: 14px !important;
      color: rgba(9, 53, 84, 1);

      .searchTextTags {
        font-size: 14px;
        color: rgba(9, 53, 84, 1);
        font-family: "Montserrat";
        font-weight: 400;
      }
    }

    .showSeachText::placeholder {
      color: rgba(9, 53, 84, 0.6) !important;
    }

    .searchHide {
      position: absolute;
      top: 0;
      width: calc(100% - 0.3rem);
      resize: none;
      padding-right: 1rem;

      > div {
        padding: 0 0 0 0;
      }

      textarea {
        height: 59px;
        color: #7d7c84;
        font-size: 0.8em;
        overflow: hidden;
        -webkit-text-fill-color: transparent;

        max-height: 4rem;
        word-break: break-word;
        width: 100%;
        font-family: "Montserrat";
        font-weight: 400;
        line-height: 1.6em;
        letter-spacing: 0.56546px;
        text-transform: none;

        &::placeholder {
          -webkit-text-fill-color: #7d7c84;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .searchHideTitle {
      flex: 1;

      > div {
        padding: 0 0 0 0;
      }

      textarea {
        height: 59px;
        color: #7d7c84;
        font-size: 0.8em;
        overflow: hidden;
        -webkit-text-fill-color: transparent;

        max-height: 4rem;
        word-break: break-word;
        width: 100%;
        font-family: "Montserrat";
        font-weight: 400;
        line-height: 1.6em;
        letter-spacing: 0.56546px;
        text-transform: none;

        &::placeholder {
          -webkit-text-fill-color: #7d7c84;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .nameField {
      > div > input {
        padding-left: 5px !important;
      }
    }

    .search {
      width: 100%;
      padding-right: 1em;

      div > div {
        padding: 0 0 0 0.5em;

        &::before,
        &::after {
          content: none;
        }
      }

      textarea {
        font-size: 14px;
        padding: 0 0 0 1em;
        font-family: "Montserrat";
        font-weight: 400;
        color: rgba(9, 53, 84, 1);
        height: auto !important;
      }

      .boolKeys {
        margin: 0 0.2rem;
        color: #24325f;
        font-weight: 600;
      }

      button {
        display: none;
      }

      .searchTextTags {
        font-size: 0.8rem;
      }
    }

    .search2 {
      width: 100%;
      padding: 0px 4px;
      div > div {
        padding: 0;

        &::before,
        &::after {
          content: none;
        }
      }

      textarea {
        font-size: 14px;
        font-weight: 400;
        font-family: "Montserrat";
        color: rgba(9, 53, 84, 1);
        text-wrap: wrap;
        height: auto !important;
      }

      .boolKeys {
        margin: 0 0.2rem;
        color: #24325f;
        font-weight: 600;
        font-size: 14px;
      }

      button {
        display: none;
      }

      .searchTextTags {
        font-size: 14px;
      }
    }

    .titleSearch {
      > div {
        width: auto;
      }

      input {
        font-size: 0.8em;
        padding-left: 8px;
        padding-top: 2.7px;
        letter-spacing: 0.56546px;
      }
    }

    .filterSubTitle {
      font-size: 12px;
      color: rgba(9, 53, 84, 1);
      font-family: "Montserrat";
      font-weight: 400;

      span {
        font-size: 0.97em;

        svg {
          width: 0.8em;
          height: 0.8em;
        }
      }
    }

    .filterDropDown {
      padding: 0;
      font-size: 12px;
      margin-left: 10px;
      color: rgba(135, 135, 135, 1);
      font-weight: 400;
      font-family: "Montserrat";

      &::before {
        content: none;
      }

      & > div {
        // padding: 0;
        padding-right: 19px;
      }

      svg {
        width: 0.8em;
        height: 0.8em;
        margin-top: 2px;
      }
    }
  }

  .filterSearch {
    background-color: rgba(9, 53, 84, 1);
    border-radius: 12px;
    padding: 14px 20px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(250, 250, 248, 1);
    font-weight: 500;
    font-family: "Montserrat";
    cursor: pointer;
    margin: 12px 0px;
  }

  .filterReset {
    background-color: transparent;
    border-radius: 12px;
    padding: 14px 20px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(9, 53, 84, 1);
    font-weight: 500;
    font-family: "Montserrat";
    border: 1px solid rgba(9, 53, 84, 1);
    cursor: pointer;
  }

  .filterAdvanceSearch {
    background-color: rgba(1, 92, 147, 1);
    border-radius: 12px;
    padding: 14px 20px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(250, 250, 248, 1);
    font-weight: 500;
    font-family: "Montserrat";
    cursor: pointer;
    border-radius: 12px;
    padding: 14px 20px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(250, 250, 248, 1);
    font-weight: 500;
    font-family: "Montserrat";
    cursor: pointer;
  }
}

.carousel {
  button {
    background-color: #ffffff;

    &.rec-arrow {
      width: 2em;
      height: 2em;
      min-width: 2em;
      line-height: 1em;
      font-size: 0.8em;
      //   [theme.breakpoints.down('sm')]{
      //     display: none
      //   }
    }
  }

  & h5 {
    height: 15%;
    //   [theme.breakpoints.down('sm')]{
    //     height: auto,
    //   }
  }
}

.noDataFound {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleInput .inputRoot textarea {
  letter-spacing: 0.56546px;
  width: fit-content;
  font-size: 14px;
}

.inputRoot {
  .MuiAutocomplete-inputRoot {
    padding-right: 0 !important;
    font-size: 14px !important;
  }

  input {
    padding: 2px 0px;
    background-color: red !important;
    font-size: 14px;
  }
}

.yearsExp {
  div {
    padding: 8px 12px;
    border-radius: 12px;
    background-color: rgba(248, 246, 246, 1);

    &::before,
    &::after {
      content: none;
    }

    input {
      font-size: 14px;
      color: rgba(9, 53, 84, 1);
      font-family: "Montserrat";
      font-weight: 400;
    }
  }
}

.searchModalDiv {
  background-color: #093554 !important;
  color: #fafaf8 !important;
  padding: 14px 20px;
  border-radius: 12px !important;
  margin-top: 20px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  flex: 1;
  outline: none;
  transition: all 0.4s;
}

.chooseFileBtn {
  background-color: #093554;
}

.resetModalDiv {
  padding: 14px 20px;
  border-radius: 12px !important;
  margin-top: 20px;
  height: 45px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #093554 1px solid !important;
  outline: none;
  color: #093554 !important;
  transition: all 0.4s;
}

.expDiv {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}

.aSearchPlaceHolderTxt {
  font-size: 14px;
  font-family: "Montserrat";
  color: #093554ad;
}

.advanceSearchTitle {
  color: #093554;
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.aSearchHeading {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  color: #093554;
}

.uploadedFileTxt {
  font-family: "Montserrat";
  font-size: 14px;
  color: #093554;
}

.mt20 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}

.uploadJdDiv {
  margin-top: 32px;
  align-self: center;
}

.aSearchBtnsDiv {
  margin-top: 26px;
  display: flex;
  flex: 1;
  column-gap: 12px;
}

.fileinputContainer {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  align-self: center;
}

.fileinputbutton {
  background-color: #015c93;
  color: white;
  padding: 10px 20px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  align-self: center;
}

.fileinput {
  position: absolute;
  font-size: 100px;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #093554;
  font-size: 14px;
  cursor: pointer;
  z-index: 999;
}

.uploadImg {
  height: 20px;
  width: 20px;
  margin-left: 5px;
}

.fileName {
  margin-left: 20px;
  font-size: 14px;
  color: #093554;
  font-family: "Montserrat";
  font-weight: 400;
}
