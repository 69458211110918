.newsLetterMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 14px;
  margin-bottom: 42px;
  padding-inline: 40px;
}

.newsTitle {
  font-size: 24px;
  line-height: 31.2px;
}

.newsDesc {
  font-size: 16px;
  padding-inline: 0px;
}

.subsTxt {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #fafaf8;
  margin-bottom: 0px;
}

.subscribeBtnDiv {
  background-color: #093554;
  padding: 14px 20px;
  border-radius: 12px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  outline: none;
}

.newsInput {
  width: 100%;
  border: 0px #ffffff solid;
  padding: 10px 12px;
  border-radius: 12px;
  font-size: 14px;
  outline: none;
  color: rgba(9, 53, 84, 0.6);
  font-weight: 500;
  transition: outline 450ms ease;
}

.newsInput:focus {
  outline: 1px solid #093554;
}

.newsInput::placeholder {
  font-weight: 300;
}

.textInputDiv {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.news-input-div {
  width: 100%;
  max-width: 500px;
}

@media screen and (max-width: 768px) {
  .newsTitle {
    font-size: 18px;
  }

  .newsDesc {
    font-size: 14px;
  }

  .subscribeBtnDiv {
    padding: 12px;
  }

  .subsTxt {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .newsTitle {
    font-size: 12px;
  }

  .newsDesc {
    font-size: 12px;
  }

  .newsInput {
    font-size: 12px;
  }
}
