.navbar-div {
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
  padding: 18px 80px;
  background-color: #12181f;
}

.main-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
}

.logo-header-text {
  font-size: 24px;
  color: #fafaf8;
  font-weight: 600;
  letter-spacing: 6px;
  line-height: 32px;
  transition: all 0.5s ease;
}

.logo-tagline-text {
  font-size: 8px;
  text-transform: uppercase;
  color: #fafaf8;
  font-weight: 400;
  letter-spacing: 1.2px;
  transition: all 0.5s ease;
}

.logo-header-div {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.logo {
  background-image: url("/src/assets/icons/LogoHeader.svg");
  width: 38.29px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
}

.logo-div {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.nav-link {
  font-size: 14px;
  color: rgba(250, 250, 248, 0.8);
  padding: 8px 10px;
  font-weight: 300;
  transition: color 0.3s ease, border-bottom 0.3s ease, font-weight 300ms ease;
}

.nav-link-contact {
  font-size: 14px;
  color: rgba(250, 250, 248, 0.8);
  padding: 8px 10px;
  font-weight: 300;
  text-decoration: none;
}

.nav-link,
.nav-link-contact {
  font-size: 14px;
  color: rgba(250, 250, 248, 0.8);
  padding: 8px 10px;
  font-weight: 300;
  position: relative; /* Needed for absolute positioning of ::after */
  transition: color 0.3s ease, font-weight 300ms ease;
}

.nav-link::after,
.nav-link-contact::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: rgba(250, 250, 248, 0.8);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-link:hover {
  color: rgba(250, 250, 248, 0.8);
}

.nav-link-contact:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-link-contact:hover {
  color: rgba(250, 250, 248, 0.8);
}

.nav-link.active {
  color: rgba(250, 250, 248, 1);
  font-weight: 600;
}

.custom-toggler {
  display: none;
}

.menu-container {
  position: relative;
}

.menu-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 48px;
  width: 48px;
  padding: 14px 13px;
}

.bar1,
.bar2,
.bar3 {
  width: 100%;
  border-bottom: 2px solid #fafaf8;
  transition: all 0.3s;
}

.menu-toggle.open .bar1 {
  transform: rotate(-45deg) translateX(-5px) translateY(5px);
}

.menu-toggle.open .bar3 {
  transform: rotate(45deg) translateX(-8px) translateY(-8px);
}

.menu-toggle.open .bar2 {
  display: none;
}

.nav-menu {
  position: absolute;
  top: 80px;
  width: 100%;
  left: 0;
  background-color: #12181f;
  opacity: 0;
  transition: opacity 0.3s, transform 1s ease-in-out;
  transform: translateY(-10px);
  padding: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  /* Prevent clicks when menu is closed */
}

.nav-menu.open .responsive-nav-items {
  visibility: visible;
  /* Show items when menu is open */
  opacity: 1;
  /* Make items visible when menu is open */
}

.nav-menu.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  /* Allow clicks when menu is open */
}

.responsive-nav {
  display: none;
}

.nav-div-items {
  display: flex;
  align-items: center;
  column-gap: 12px;
  box-sizing: border-box;
}

.responsive-nav-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  /* Hide items when menu is closed */
  opacity: 0;
  /* Make items transparent when menu is closed */
  transition: visibility 0s, opacity 0.5s linear;
  /* Smooth transition */
}

.contactUsModal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  max-width: 600px;
}

.fade {
  padding-left: 0px;
}

.modalBoxContact {
  width: 100%;
  /* max-width: 600px; */
  height: auto;
  /* max-height: 801px; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
}

.crossContactImg {
  height: 26px;
  width: 26px;
  object-fit: contain;
  cursor: pointer;
  transition: all 0.4s;
}

.crossContactImg:hover {
  transform: translateX(3px);
}

.contactTitleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contactTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: #093554;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.contactSubText {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #093554;
  margin-bottom: 0px;
  text-align: center;
}

.contactSubTitleDiv {
  padding-top: 22px;
}

.contactusFormTitle {
  font-size: 14px;
  line-height: 17.07px;
  font-weight: 500;
  color: #093554;
  margin-bottom: 12px;
}

.contactusInput {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  padding: 10px 12px;
  border-radius: 12px;
  border: 1px #ffffff solid;
  background-color: #f8f6f6;
  align-items: center;
}

.contactusDuoTxInputError {
  border-color: red;
}

.countryPickerInput {
  padding: 4px;
}

.contactImgDuo {
  height: 20px;
  width: 20px;
}

.contactImgForm {
  height: 20px;
  width: 20px;
}

.contactFormDiv {
  margin: 32px 0px 0px;
}

.firstInnerDiv {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}

.firstNameDiv {
  flex: 1;
}

.secondNameDiv {
  flex: 1;
}

.contactusDuoTxInput {
  flex: 1;
  outline: none;
  font-size: 14px;
  border: none;
  padding: 0px;
  background-color: #f8f6f6;
}

.errorInput {
  border-width: 1;
  border-color: red;
}

.contactusTxInput {
  font-size: 14px;
  border: none;
  flex: 1;
  background-color: #f8f6f6;
  outline: none;
}

.ContactFormFieldDiv {
  margin-top: 20px;
}

.submitBtnDiv {
  background-color: #093554;
  padding: 14px 20px;
  border-radius: 12px;
  margin-top: 20px;
  width: 140px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  transition: all 0.4s;
}

.submitBtnDiv:hover {
  transform: translateY(-5px);
}

.submitTxt {
  font-size: 14px;
  font-weight: 500;
  color: #fafaf8;
  margin-bottom: 0px;
}

.mainDiv {
  display: flex;
  justify-content: center;
}

.infoDiv {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.infoText1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #093554;
  margin-bottom: 0px;
  text-align: center;
}

.infoText2 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  color: #093554;
  margin-bottom: 0px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.infoText3 {
  font-size: 16px;
  font-weight: 400;
  color: #a8a8a8;
  text-align: center;
  margin-bottom: 0px;
}

.applyMain {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.applyBtnDiv {
  border-radius: 12px;
  padding: 14px 20px;
  background-color: #015c93;
  border: none;
  outline: none;
  transition: all 0.4s;
}

.applyBtnDiv:hover {
  transform: translateY(-5px);
}

.applyText {
  font-size: 14px;
  font-weight: 500;
  color: #fafaf8;
  margin-bottom: 0px;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.errorDiv {
  /* min-height: 5px; */
}

.nav-contact-btn {
  padding: 10px 12px;
  background-color: rgba(250, 250, 250, 0.4);
  text-decoration: none;
  border-radius: 6px;
  font-size: 14px;
  color: rgba(250, 250, 248, 1);
  font-weight: 300;
  transition: all 0.4s ease;
}

.nav-contact-btn:hover {
  background-color: transparent;
  outline: 1px solid rgba(250, 250, 248, 1);
}

.errorTxt {
  min-height: 5px;
  font-size: 10px;
  color: #ee855e;
  text-align: start;
  padding-inline: 10px;
  margin-bottom: 0px;
  /* line-height: 18px; */
}

@media screen and (max-width: 1084px) {
  .navbar-div {
    padding: 18px 40px;
  }
}

@media screen and (max-width: 960px) {
  .nav-div-items {
    display: none;
  }

  .responsive-nav {
    display: block;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
  .navbar-div {
    padding: 18px 20px;
  }

  .logo {
    width: 26.8px;
    height: 28px;
  }

  .logo-header-text {
    font-size: 17px;
    line-height: 22.15px;
  }

  .logo-tagline-text {
    font-size: 5px;
    line-height: 7.2px;
  }
}
