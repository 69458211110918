.card-container {
  position: relative;
  width: 380px;
  height: auto;
  overflow: hidden;
  border-radius: 14px;
  background-color: transparent;
  box-shadow: 0px 15px 26px -22px #15151594;
  transition: transform 0.3s;
}

.card-image {
  width: 100%;
  max-height: 420px;
  height: auto;
  object-fit: cover;
}

.card-data {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.86);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  overflow-y: auto;
}

.card-container:hover .card-data {
  opacity: 1;
}

.card-data h3,
.card-data p {
  text-align: center;
}

.team-linkdin-btn {
  padding: 12px;
  border-radius: 10px;
  border: none;
  background-color: rgba(9, 53, 84, 1);
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
}

.leader-name {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
}

.linkedLogo {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.leader-bio {
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  font-weight: 300;
}

.partner-card-container {
  position: relative;
  width: 282px;
  height: auto;
  overflow: hidden;
  border-radius: 14px;
  background-color: transparent;
  box-shadow: 0px 15px 26px -22px #15151594;
  transition: transform 0.3s;
}

.partner-card-image {
  width: 100%;
  max-height: 400px;
  height: auto;
  object-fit: cover;
}

.partner-card-container:hover .card-data {
  opacity: 1;
}

.partner-card-container:hover {
  transform: translateY(-10px);
}

/* team card css  */

.leader-img {
  width: 200px;
  height: 200px;
  border-radius: 16px;
  object-fit: fill;
}

.leader-img-div {
  width: 200px;
  height: 200px;
  border-radius: 16px;
  position: relative;
  bottom: 40px;
  left: 12px;
}

.leader-info-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 260px;
  gap: 12px;
}

.leader-name-text {
  font-size: 22px;
  font-weight: 800;
  color: #ffffff;
  text-wrap: wrap;
  text-align: center;
}

.leader-status {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
}

.team-card-div {
  display: flex;
  justify-content: space-between;
  height: 156px;
  max-width: 620px;
  min-width: 500px;
  padding: 32px 20px;
  position: relative;
  border-radius: 14px;
  background-color: #015c93;
  align-items: center;
  box-sizing: border-box;
  flex: 1;
}

.team-card-div:nth-child(4n + 2),
.team-card-div:nth-child(4n + 3) {
  background-color: #093554;
}

@media screen and (max-width: 1024px) {
  .card-container {
    width: 230px;
  }

  .partner-card-container {
    width: 173px;
  }

  .card-data {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .leader-name {
    font-size: 12px;
  }

  .leader-bio {
    font-size: 10px;
    line-height: 12px;
  }

  .leader-img-div {
    width: 120px;
    height: 140px;
    box-shadow: 0px 15px 26px -22px #15151594;
    border-radius: 16px;
    bottom: 25px;
    left: 0px;
  }

  .leader-img {
    width: 120px;
    height: 140px;
    border-radius: 16px;
    object-fit: cover;
  }

  .team-card-div {
    max-width: 400px;
    min-width: 340px;
    height: 130px;
    padding: 32px 14px;
  }

  .leader-name-text {
    font-size: 16px;
  }

  .leader-status {
    font-size: 12px;
  }

  .leader-info-div {
    gap: 6px;
  }
}
